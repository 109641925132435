import { JSX, useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonProps } from '@mui/material';
import RefreshIcon from "@mui/icons-material/Refresh";
import ChatItem from './ChatItem';
import ChatInput from './ChatInput';
import { MessageType } from './ChatItem';
import variables from "../../styles/vars.module.scss";
import useWelcomeMessage from '../../hooks/useWelcomeMessage';
import { Statuses } from '../../common/types/enum';
import { ProgressResult } from '../../common/types/resultMessage';
import { CenteredLoader } from '../Elements/CenteredLoader';

const Chat = (props:any): JSX.Element => {
  const { welcomeObject, isLoading, isError } = useWelcomeMessage(props.pilotName);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [stateProgress, setStateProgress] = useState<ProgressResult>({
    progress: 0,
    status: null
  });
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (welcomeObject?.clientName) {
      props.onClientName(welcomeObject.clientName);
      props.onTitle(welcomeObject.title);
      // document.title = welcomeObject.title || 'JumpStart';
      setMessages([]);
    } else if (isError) {
      props.onTitle('This pilot does not exist. Please contact Glenflow Support team');
    }
  }, [welcomeObject, isError]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  const refresh = () => {
    if(typeof window !== 'undefined'){
      window.location.reload();
    }
  };

  if (isError) {
    return (<Box></Box>)
  }

  if (isLoading) {
    return (<CenteredLoader/>)
  }

  return (
    <StyledChat>
      <Box className="chat-container scrollbar-y" ref={messagesContainerRef}>
        { welcomeObject &&
            <Box className="welcome-message">
              <ChatItem
                  key={welcomeObject.session}
                  message={{
                    id: Date.now(),
                    role: 'Assistance',
                    text: welcomeObject?.message,
                  }}
              />
            </Box>
        }
        {messages && messages.map((message) => (
          <ChatItem key={message.id} message={message} />
        ))}

        {stateProgress.status === Statuses.IN_PROGRESS && (
          <Box className="button-container">
            <ChatItem key={0} message={{
              id: 0,
              role: 'Assistance',
              progress: stateProgress.progress || 1,
              text: 'Generating responses for you...'
            }} />
          </Box>
        )}

        {messages.length > 1 && (
          <Box className="button-container">
            <ReloadButton variant="contained" startIcon={<RefreshIcon />} onClick={refresh}>
              Clear and Restart
            </ReloadButton>
          </Box>
        )}
      </Box>
      <Box className="chat-input">
        <ChatInput scenarioId={welcomeObject.scenarioId} onProgress={setStateProgress} onMessage={setMessages} />
      </Box>
    </StyledChat>
  );
};

export default Chat;

const StyledChat = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  overflow: 'hidden',
  flexDirection: "column",
  maxWidth: 1025,
  width: '100%',
  paddingRight: '30px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    paddingRight: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: '10px',
  },

  '& .welcome-message': {
    whiteSpace: 'break-spaces'
  },

  '& .chat-input': {
    marginLeft: '50px',
  },

  '& .chat-container': {
    flex: 1,
    paddingRight: '30px',
    marginRight: '-30px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px',
      marginRight: '-20px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '10px',
      marginRight: '-10px',
    },
  },
  '& .button-container': {
    width: '100%',
    textAlign: 'center',
  },
}));

const ReloadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: 45,
  border: '1px solid #E2E8F0',
  boxShadow: 'none',
  backgroundColor: '#fff',
  textTransform: 'none',
  color: variables.textColor,
  fontSize: 16,
  maxWidth: 280,
  width: '100%',
  fontWeight: 700,
  letterSpacing: '-0.16px',
  padding: theme.spacing(1.6),
  [theme.breakpoints.down('sm')]: {
    maxWidth: 250,
  },

  '&:hover': {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  '& .MuiSvgIcon-root': {
    fill: variables.mainColorPrimary,
  },
}));
