import { useQuery } from 'react-query';
import { fetchWelcomeMessage } from '../api/welcomeMessage';
import { WelcomeMessageResponse } from '../common/types/welcomeMessage';
import {sessionNameStorage} from '../common/constants';

const useWelcomeMessage = (pilotName: string) => {
    const { data, isLoading, isError } = useQuery(
        ['welcomeMessage', pilotName], () => fetchWelcomeMessage(pilotName),
        {
            retry: 0,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        }
    );

    if (data) {
        sessionStorage.setItem(sessionNameStorage, data.session);
    }

    return {
        welcomeObject: data as WelcomeMessageResponse,
        isLoading,
        isError
    };
};

export default useWelcomeMessage;
