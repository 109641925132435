import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "./common/constants";

const instanceOptions: Partial<AxiosRequestConfig> = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const httpClient = axios.create({
  ...instanceOptions,
  baseURL: API_BASE_URL,
});
