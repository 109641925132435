import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
  title: string;
  onDrawerToggleClick: () => void;
}

export default function Header(props: Props) {
  return (
    <StyledAppBar>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.onDrawerToggleClick}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Typography variant="h1" component="h3">
          {props.title}
        </Typography>
      </Toolbar>
    </StyledAppBar>
  );
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  background: 'transparent',
  boxShadow: 'none',
  marginBottom: 10,
  '& .MuiToolbar-root': {
    padding: 0,
  },
  '& .MuiButtonBase-root': {
    marginRight: 6,
    marginLeft: -9,
  },
  '& .MuiTypography-root': {
    width: '100%',
    textAlign: 'center',
  },
}));
