export enum Roles {
    User = 'User',
    Assistance = 'Assistance',
    Error = 'Error'
}

export enum Statuses {
    DONE = 'DONE',
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROCESS'
}
