import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const CenteredLoader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'translateY(-5%)'
            }}
        >
            <CircularProgress
                size={60}
            />
        </Box>
    );
};