import { createTheme } from '@mui/material/styles';
import variables from './styles/vars.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: variables.mainColorPrimary,
    },
    secondary: {
      main: variables.mainColorSecondary,
    },
    error: {
      main: variables.redColor,
    },
  },
  typography: {
    fontFamily: 'Mulish',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 700,
      '@media (max-width:1199px)': {
        fontSize: '1.4rem',
      },
      '@media (max-width:599px)': {
        fontSize: '1.3rem',
      },
    },
    h2: {
      fontSize: '1.4em',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 700,
    },
  },
});

export default theme;
