import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Link } from "@mui/material";
import variables from "../../../styles/vars.module.scss";

const preventDefault = (event: React.SyntheticEvent) => {
  if (event.target === event.currentTarget) {
    event.preventDefault();
  }
};

export default function Nav() {

  return (
      <StyledBox
          sx={{
            typography: 'body1',
          }}
          onClick={preventDefault}
      >
        <Link
            href="https://glenflow.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
        <Link
            href="https://glenflow.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </StyledBox>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: variables.backgroundDarkPrimary,
  '& .MuiLink-root': {
    display: 'inline-block',
    fontSize: 13,
    color: '#718096',
    margin: theme.spacing(1.5, 2),
    textDecoration: 'none',
    borderColor: 'rgba(226, 232, 240, 0.20)',
    '&:hover': {
      color: '#FFF',
    },
  },
}));
