import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Snackbar,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import variables from '../../styles/vars.module.scss';

type PositionV = 'bottom' | 'top';
type PositionH = 'left' | 'right';
type MessageType = 'info' | 'success' | 'warning' | 'error';

interface Props {
  open: boolean;
  type?: MessageType;
  title: string;
  message: string;
  vertical?: PositionV;
  horizontal?: PositionH;
  onClose?: () => void;
}

const PositionedSnackbar: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(props.open);
  const vertical = props.vertical || 'top';
  const horizontal = props.horizontal || 'right';
  const type = props.type || 'info';
  const title = props.title;
  const message = props.message;

  const handleClose = (): void => {
    setOpen(false);
  };

  const getIcon = (type: MessageType): JSX.Element | null => {
    switch (type) {
      case 'success':
        return <CheckCircleOutlineOutlinedIcon fontSize="small" />;
      case 'info':
        return <NotificationsOutlinedIcon fontSize="small" />;
      case 'warning':
        return <ReportProblemOutlinedIcon fontSize="small" />;
      case 'error':
        return <HighlightOffIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const action = (
    <IconButton aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} key={vertical + horizontal} action={action}>
      <StyledAlert icon={getIcon(type)} onClose={handleClose} variant="outlined" severity={type}>
        {title && <AlertTitle>{title}</AlertTitle>}
        <Typography className="alert-message">
          {message}
        </Typography>
      </StyledAlert>
    </Snackbar>
  );
};

export default PositionedSnackbar;

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: '#fff',
  width: 358,
  boxShadow: '0px 26px 24px 0px rgba(0, 0, 0, 0.03)',
  borderRadius: '24px',
  border: 'none',
  padding: theme.spacing(2),

  '&.MuiAlert-outlinedInfo': {
    color: variables.mainColorPrimary,
    '& .MuiAlert-icon': {
      color: variables.mainColorPrimary,
    },
  },
  '&.MuiAlert-outlinedSuccess': {
    color: theme.palette.success.main,
    '& .MuiAlert-icon': {
      color: theme.palette.success.main,
    },
  },
  '&.MuiAlert-outlinedWarning': {
    color: theme.palette.warning.main,
    '& .MuiAlert-icon': {
      color: theme.palette.warning.main,
    },
  },
  '&.MuiAlert-outlinedError': {
    color: theme.palette.error.main,
    '& .MuiAlert-icon': {
      color: theme.palette.error.main,
    },
  },
  '& .MuiAlert-message': {
    fontSize: '13px',
    lineHeight: '16px',
  },
  '& .MuiAlertTitle-root': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.2,
    color: 'inherit',
    marginBottom: 8,
  },
  '& .alert-message': {
    fontSize: '14px',
    lineHeight: '160%',
    color: variables.textColor,
  },
  '& .MuiAlert-icon': {
    padding: 0,
    marginRight: 10,
    '& .MuiSvgIcon-root': {
      width: '1.35em',
      height: '1.35em',
      [theme.breakpoints.down('sm')]: {
        width: '1.2em',
        height: '1.2em',
      },
    },
  },
  '& .MuiAlert-action': {
    paddingTop: 0,
    marginTop: '-3px',
    paddingLeft: 10,
    '.MuiSvgIcon-root': {
      width: 25,
      height: 25,
      color: variables.textColor,
    },
  },
}));
