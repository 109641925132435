import { httpClient } from "../HttpClients";
import { sessionNameStorage, sessionHeaderName } from "../common/constants";

export const getResult = async (requestId: string) => {
  const response = await httpClient.get(`result/${requestId}`, {
    headers: {
      [sessionHeaderName]: sessionStorage.getItem(sessionNameStorage),
    },
  });

  return {
    data: response.data,
    status: response.status,
  };
};
