import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from '../Sidebar';
import Header from '../Header';

type LayoutProps = {
    children: ReactNode;
    clientName: string;
    title?: string
};

const Layout: FC<LayoutProps> = ({ children, clientName, title }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };


  return (
    <StyledDiv className="app-container">
      <CssBaseline />
      <Box className="app-container__sidebar">
        <Sidebar clientName={clientName} open={drawerOpen} onClose={handleDrawerToggle} />
      </Box>
      <Box className="app-container__main">
        <Header
          title={title || ''}
          onDrawerToggleClick={handleDrawerToggle} />
        {children}
      </Box>
    </StyledDiv>
  );
}

export default Layout;

const StyledDiv = styled.div`
  position: relative;
  display: flex; 
  height: 100vh;
  align-items: stretch;
  overflow: hidden;
  width: 100%;

  .app-container{
    &__sidebar{
      flex-shrink: 0;
    }
    &__main{
      width: 100%;
      padding: 20px 30px 15px 30px;
      background: var(--backgroundGradientPrimary);
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;

      @media (max-width: 1199px) {
        padding: 15px 20px;
      }
      @media (max-width: 899px) {
        padding: 15px;
      }
    }
  }
`;
