import { RegisterMessageBody } from "../common/types/registerMessage";
import { httpClient } from "../HttpClients";
import { sessionNameStorage, sessionHeaderName } from "../common/constants";

export const registratorMessage = async (body: RegisterMessageBody) => {
  const response = await httpClient.post("registrator", body, {
    headers: {
      [sessionHeaderName]: sessionStorage.getItem(sessionNameStorage),
    },
  });
  return response.data;
};
