import { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import Layout from './components/Layout';
import Chat from './components/Chat'
import Snackbar from './components/Elements/Snackbar'
import theme from './theme';
import './App.scss';
import { useGetPilotNameFromUrl } from './hooks/useGetPilotNameFromUrl';


function App() {
  const { pilotName } = useGetPilotNameFromUrl();
  const [clientName, setClientName] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  return (
    <ThemeProvider theme={theme}>
      <Layout clientName={clientName} title={title}>
        <Chat pilotName={pilotName} onClientName={setClientName} onTitle={setTitle} />
        <Snackbar
          type="error"
          title="Error file upload"
          message="File upload only supports this format, please try again"
          open={false}
        />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
