import { useState, useCallback } from 'react';

type UseFileReader = {
    content: string | null;
    error: DOMException | null;
    read: (file: File) => void;
    setContent: (content: string | null) => void;
}

export const useFileReader = (): UseFileReader => {
    const [content, setContent] = useState<string | null>(null);
    const [error, setError] = useState<DOMException | null>(null);

    const read = useCallback((file: File) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            setContent(event.target?.result as string);
        };

        reader.onerror = (err) => {
            setError(err.target ? err.target.error : null);
                console.error('File read error:', err.target?.error);
        };
        reader.readAsText(file);
    }, []);

    return {
        setContent,
        content,
        error,
        read,
    };
};
