import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  Drawer,
  Button,
  ButtonProps,
  Stack,
  Typography
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import Logo from '../Sidebar/Logo';
import Copyright from '../Sidebar/Copyright';
import Nav from '../Sidebar/Nav';
import variables from '../../styles/vars.module.scss';

const drawerWidth = 280;

interface Props {
  open: boolean;
  clientName: string
  onClose: () => void;
}

export default function ResponsiveDrawer(props: Props) {

  const refresh = () => {
    if(typeof window !== 'undefined'){
      window.location.reload();
    }
  };
  const drawer = (
    <Box className="sidebar-drawer">
      <Box className="sidebar-drawer__main">
        <Stack className="sidebar-drawer__header" direction="column">
          <Logo />
          <Divider />
          { props.clientName &&
            <>
              <Box display="flex" alignItems="center" marginBottom="0.1rem" marginTop="-1rem">
                <ClientNameTypography>
                  { props.clientName }
                </ClientNameTypography>
              </Box>
              <Divider />
            </>
          }
          <GradientButton variant="contained" startIcon={<RefreshIcon />} onClick={refresh}>
            Clear and Restart
          </GradientButton>
        </Stack>
        <Stack className="sidebar-drawer__footer" direction="column">
          <Divider />
          <Copyright />
        </Stack>
      </Box>
      <Box className="sidebar-drawer__nav">
        <Nav />
      </Box>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
    >
      <StyledDrawer
        variant="temporary"
        open={props.open}
        onClose={props.onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { width: drawerWidth },
        }}
      >
        {drawer}
      </StyledDrawer>
      <StyledDrawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { width: drawerWidth },
        }}
        open
      >
        {drawer}
      </StyledDrawer>
    </Box>
  );
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: variables.backgroundGradientSecondary,
    boxSizing: 'border-box',
  },
  '& .sidebar-drawer': {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&:after': {
      content: 'url("/images/sidebar-bg.svg")',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: -1,
    },
  },
  '& .sidebar-drawer__main': {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  '& .sidebar-drawer__header': {
    padding: theme.spacing(2.5, 3.8),
    '& .MuiDivider-fullWidth': {
      margin: '7px 0px 27px 0',
      borderColor: 'rgba(226, 232, 240, 0.20)',
    },
  },
  '& .sidebar-drawer__footer': {
    padding: theme.spacing(2.5, 3.8),
    '& .MuiDivider-fullWidth': {
      margin: '20px 0',
      borderColor: 'rgba(226, 232, 240, 0.20)',
    },
  }
}));

const GradientButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: 30,
  background: variables.mainGradientPrimary,
  textTransform: 'none',
  color: '#fff',
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: '-0.16px',
  padding: theme.spacing(1.2),
}));

const ClientNameTypography = styled(Typography)(({ theme }) => ({
  justifyContent: 'center',
  color: '#fff',
  fontSize: 19,
  fontWeight: 700,
  margin: theme.spacing(0, 0, 2)
}));
